<template>
    <div class="about-child-wrp">
        <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 合作伙伴 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                    <!-- 全球互联网教育平台运营商，致力于构建终身教育生态系统 -->
                </p>
            </div>
        </div>
        <div class="yz-par-con">
            <div class="yz-par-item">
                <img src="../../../assets/images/partners01.jpg" class="item01" >
                <img src="../../../assets/images/partners02.jpg" class="item01">
                <img src="../../../assets/images/partners03.jpg" class="item01">
            </div>
            <div class="yz-par-item  p1">
                <p>联合国教科文组织战略合作伙伴</p>
                <p>上海合作组织战略合作伙伴</p>
                <p>国际互联网教育联盟单位</p>
            </div>
            <div class="yz-par-item">
                <img src="../../../assets/images/partners04.jpg" class="item02">
                <img src="../../../assets/images/partners05.jpg" class="item02">
                <img src="../../../assets/images/partners06.jpg" class="item02">
            </div>
        </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less">
   .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
    .yz-par-con{
        width: 1200px;
        margin:0 auto;
        min-height: 500px;
        .yz-par-item{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
             width: 100%;
             height: 280px;
            .item01{
                width: 30%;
                height: 230px;
                margin: 20px;
            }
            p{
                color:#fff;
                font-size: 16px;
                background: #0184d2;
                border-radius:10px;
                width: 30%;
                margin: 20px;
            }
            .item02{
                width: 15%;
                height: 170px;
                margin: 50px 110px;
            }
        }
    }
    .p1{
        height: 50px !important;
        line-height: 50px;
    }
</style>
